import React from "react";
import Contact from "../components/Contact";
import PricingDetails from "../components/PricingDetails";

const Pricing = () => {
  return (
    <>
      <main>
        <h2>PRICING</h2>
      </main>
      <PricingDetails />
      <div>
        <Contact />
      </div>
    </>
  );
};

export default Pricing;
