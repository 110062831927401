import "./App.css";
import Header from "./components/Header";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Reservation from "./pages/Reservation";
import Pricing from "./pages/Pricing";
import ContactUs from "./pages/ContactUs";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAVIPTvRm7L9iafecrurjCLFWFhl_TLX0c",
  authDomain: "schertz-rv-park.firebaseapp.com",
  projectId: "schertz-rv-park",
  storageBucket: "schertz-rv-park.appspot.com",
  messagingSenderId: "175932174098",
  appId: "1:175932174098:web:9eec7eceef2e4ae82e4dcb",
  measurementId: "G-M41XT930JB",
};

// Initialize Firebase
initializeApp(firebaseConfig);
//const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);

function App() {
  return (
    <div className='App'>
      <Header />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='reservation' element={<Reservation />} />
        <Route path='pricing' element={<Pricing />} />
        <Route path='contact' element={<ContactUs />} />
      </Routes>
    </div>
  );
}

export default App;
