import React from "react";

const Contact = () => {
  return (
    <>
      <br />
      5750 FM1103
      <br />
      Schertz, TX 78108
      <br />
      <a href='mailto:manager@schertzrvpark.com'>manager@schertzrvpark.com</a>
      <br />
      <br />
      <a href='tel:210-955-3827'>(210) 955-3827</a>
    </>
  );
};

export default Contact;
