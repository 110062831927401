import { Box, Button, TextField } from "@mui/material";
import * as React from "react";
import Contact from "../components/Contact";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { DesktopDatePicker } from "@mui/lab";
import { useState, useEffect } from "react";

const Reservation = () => {
  const pricePerNight = 40;
  const pricePerWeek = 175;
  const pricePerMonth = 550;

  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);

  const [checkInDate, setCheckInDate] = useState(today);
  const [checkOutDate, setCheckOutDate] = useState(tomorrow);
  const [numDays, setNumDays] = useState(1);

  const [total, setTotal] = useState(0);

  function round(num) {
    return Math.round(num * 100) / 100;
  }

  useEffect(() => {
    const total = round(numDays * pricePerNight);

    setTotal(total);
  }, [numDays]);

  function dateDiff(first, second) {
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  }

  useEffect(() => {
    setNumDays(dateDiff(checkInDate, checkOutDate));
  }, [checkInDate, checkOutDate]);

  const handleChange = (newValue, field) => {
    if (field === "CHECK_IN") {
      if (newValue < today) {
        return;
      }
      setCheckInDate(newValue);
      if (checkOutDate <= newValue) {
        setCheckOutDate(tomorrow.setDate(newValue.getDate() + 1));
      }
    } else if (field === "CHECK_OUT") {
      if (newValue <= checkInDate) {
        return;
      }
      setCheckOutDate(newValue);
    }
  };

  return (
    <>
      <main>
        <h2>RESERVATION</h2>
      </main>
      <Box
        component='form'
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
        }}
        noValidate
        autoComplete='off'
      >
        <div>
          <TextField
            required
            id='outlined-basic'
            label='First'
            variant='outlined'
          />
          <TextField
            required
            id='outlined-basic'
            label='Last'
            variant='outlined'
          />
        </div>
        <div>
          <TextField
            required
            id='outlined-basic'
            label='Email'
            variant='outlined'
          />
          <TextField
            required
            id='outlined-basic'
            label='Phone'
            variant='outlined'
          />
        </div>
        <div>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label='Check-In'
              inputFormat='MM/dd/yyyy'
              value={checkInDate}
              onChange={(value) => handleChange(value, "CHECK_IN")}
              renderInput={(params) => <TextField {...params} />}
            />
            <DesktopDatePicker
              label='Check-Out'
              inputFormat='MM/dd/yyyy'
              value={checkOutDate}
              onChange={(value) => handleChange(value, "CHECK_OUT")}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div>
      </Box>

      <br />
      <div>Number of Nights: {numDays}</div>
      <div>Price per Night: {`$` + pricePerNight}</div>
      <div>
        Total: <b>{`$` + total}</b>
      </div>
      <br />

      <Button variant='contained' color='primary'>
        RESERVE SPOT
      </Button>

      <div>
        <Contact />
      </div>
    </>
  );
};

export default Reservation;
