import React from "react";
import Contact from "../components/Contact";

const ContactUs = () => {
  return (
    <>
      <main>
        <h2>CONTACT US</h2>
      </main>
      <div>Maggie our on-site hostess will take care of you.</div>
      <div>
        <Contact />
      </div>
    </>
  );
};

export default ContactUs;
