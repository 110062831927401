import { Container, Stack } from "@mui/material";
import React from "react";

const Contact = () => {
  return (
    <Container maxWidth='sm'>
      <Stack spacing={2}>
        <div>
          $40 per day
          <br />
          $175 per week
          <br />
          $550 per month
        </div>
        <div>
          All utilities included.
          <br />
          No wi-fi yet.
          <br />
          2 washers 2 dryers app operated
          <br />
          Sparkling pool
          <br />
          Office undergoing renovation
          <br />
          Spots available for short term or long term
        </div>
      </Stack>
    </Container>
  );
};

export default Contact;
