import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import Contact from "../components/Contact";
import PricingDetails from "../components/PricingDetails";

const Home = () => {
  return (
    <>
      <main>
        <h2>Welcome to Schertz RV Park</h2>
      </main>
      <nav>
        <Button href='tel:210-955-3827' variant='contained' color='primary'>
          CALL NOW TO RESERVE!
        </Button>
      </nav>
      <div>
        <br />
        <PricingDetails />
        <Contact />
      </div>
    </>
  );
};

export default Home;
